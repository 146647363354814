/*eslint-disable*/
import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import FormUpload from './Upload';
import { Button, Grid, DialogContent, IconButton, Typography } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import BuildFromScratch from './BuildFromScratch';
import UploadXLSForm from './UploadXLSForm';
import CloseIcon from '@mui/icons-material/Close';

export interface UploadDialogProps {
    open: boolean;
    setOpen(val: boolean): void;
    chooseFormType: any;
    setChooseFormType: any;
    refresh?: any;
    isReplace?: boolean;
    project?: any;
}

export default function UploadDialog(props: UploadDialogProps) {
    const { setOpen, open, chooseFormType, setChooseFormType, refresh, isReplace } = props;

    const handleClose = () => {
        setChooseFormType(0);
    };
    const handleCloseModal = () => {
        setChooseFormType(0);
        setOpen(false);
    };

    console.log('project', props.project);

    return (
        <Dialog onClose={handleCloseModal} open={open} fullWidth maxWidth="sm">
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h3">{isReplace ? `Replace a Form` : `Create a Form`}</Typography>
                <IconButton onClick={handleCloseModal}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                {/* <FormUpload handleClose={handleClose} /> */}
                {chooseFormType === 0 && (
                    <>
                        <Button
                            sx={{ borderRadius: 3 }}
                            onClick={() => setChooseFormType(1)}
                            color="inherit"
                            size="large"
                            fullWidth
                            variant="outlined"
                            component="span"
                        >
                            {isReplace ? `Design Form from Form Designer` : `Build from Scratch`}
                        </Button>
                        <br />
                        <br />
                        <br />
                        <Button
                            sx={{ borderRadius: 3 }}
                            onClick={() => setChooseFormType(2)}
                            color="inherit"
                            size="large"
                            fullWidth
                            variant="outlined"
                            component="span"
                        >
                            Upload XLS Form
                        </Button>
                    </>
                )}
                {chooseFormType === 1 && (
                    <>
                        <BuildFromScratch handleClose={handleClose} refresh={refresh} handleCloseModal={handleCloseModal} />
                    </>
                )}
                {chooseFormType === 2 && (
                    <>
                        <UploadXLSForm
                            handleClose={handleClose}
                            refresh={refresh}
                            handleCloseModal={handleCloseModal}
                            isReplace={isReplace}
                            project={props.project}
                        />
                    </>
                )}
            </DialogContent>
        </Dialog>
    );
}
