import { Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';
import { checkNullInfo } from 'utils/Helpers';

// Props type
export type ColumnType = {
    header: string;
    accessor: string;
    content?: any;
};

// ==============================|| TABLE - BASIC ||============================== //

export default function DataSheetTable({
    columns,
    rows,
    link,
    linkID,
    showSL
}: any) {

    console.log('rows  ', rows);
    const navigate = useNavigate();
    const classes = useStyle();

    const renderCell = (item: any, column: any) => {
        if (column.content) {
            return column.content(item);
        }
        return <Typography noWrap>{checkNullInfo(item[column.accessor])}</Typography>;
    };

    const renderHeader = (item: any) => {
        if (typeof item.header === "string") {
            return item.header;
        }
        return item.header();
    }

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <MainCard content={false}>
                    <TableContainer>
                        <Table sx={{ minWidth: 350 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column: any, index: number) => (
                                        <TableCell key={index} align="center">
                                            <Typography variant="subtitle1" noWrap>
                                                {renderHeader(column)}
                                            </Typography>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.length ? (
                                    rows.map((row: any, i: number) => (
                                        <TableRow
                                            key={i}
                                            className={classes.rowsHover}
                                            onClick={() => (linkID ? navigate(`${link + row[linkID]}/`) : '')}
                                        >
                                            {showSL && (
                                                <TableCell align="center">
                                                    <Typography variant="subtitle1" noWrap>
                                                        {i + 1}
                                                    </Typography>
                                                </TableCell>
                                            )}
                                            {columns.map((column: ColumnType, k: number) => (
                                                <TableCell align="center" key={k}>
                                                    {renderCell(row, column)}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow className={classes.rowsHover}>
                                        <TableCell align="center" colSpan={columns.length}>
                                            <h3>No Data Found</h3>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </MainCard>
            </Grid>
        </Grid>
    );
}

const useStyle = makeStyles({
    rowsHover: {
        borderBottom: '1px solid #DDD',
        '&:hover': {
            backgroundColor: '#FFE'
        }
    }
});
