import { Autocomplete, Button, FormControlLabel, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import React from "react";
import { rows1, rows2, top100Films } from "./constants";
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import langString from "utils/langString";
import DataSheetTable from "./FormTable";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function Form2({ setStep, closeCreateView }: any) {

    const columns1: any = [
        {
            header: () => (
                <FormControlLabel
                    control={
                        <Checkbox
                            checked
                            onChange={(e: any) => console.log(e)}
                            name="active"
                            color="primary"
                        />
                    }
                    label={langString('Attribute Name')}
                />
            ),
            accessor: 'attribute_name',
            content: (item: any) => (
                <FormControlLabel
                    control={
                        <Checkbox
                            checked
                            onChange={(e: any) => console.log(e)}
                            name="active"
                            color="primary"
                        />
                    }
                    label={item.attribute_name}
                />
            )
        },
        {
            header: "New Column Title",
            accessor: 'new_column_title',
            content: (item: any) => (
                <TextField
                    variant="outlined"
                    value={item.new_column_title}
                />
            )
        },
        {
            header: 'Column Type',
            accessor: 'column_type',
            content: (item: any) => (
                <TextField
                    variant="outlined"
                    value={item.column_type}
                />
            )
        },
        {
            header: () => (
                (
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked
                                onChange={(e: any) => console.log(e)}
                                name="active"
                                color="primary"
                            />
                        }
                        label={langString('Sortable')}
                    />
                )
            ),
            accessor: 'sortable',
            content: () => (
                <Checkbox
                    onChange={(e: any) => console.log(e)}
                    name="active"
                    color="primary"
                />
            )
        },
        {
            header: () => (
                (
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked
                                onChange={(e: any) => console.log(e)}
                                name="active"
                                color="primary"
                            />
                        }
                        label={langString('Exportable')}
                    />
                )
            ),
            accessor: 'exportable',
            content: (item: any) => (
                <Checkbox
                    onChange={(e: any) => console.log(e)}
                    name="active"
                    color="primary"
                />
            )
        },
        {
            header: () => (
                <FormControlLabel
                    control={
                        <Checkbox
                            checked
                            onChange={(e: any) => console.log(e)}
                            name="active"
                            color="primary"
                        />
                    }
                    label={langString('Hidden')}
                />
            ),
            accessor: 'hidden',
            content: (item: any) => (
                <Checkbox
                    onChange={(e: any) => console.log(e)}
                    name="active"
                    color="primary"
                />
            )
        },
        {
            header: 'Order',
            accessor: 'order'
        }
    ];

    const columns2: any = [
        {
            header: () => (
                <FormControlLabel
                    control={
                        <Checkbox
                            checked
                            onChange={(e: any) => console.log(e)}
                            name="active"
                            color="primary"
                        />
                    }
                    label={langString('Attribute Name')}
                />
            ),
            accessor: 'name',
            content: (item: any) => (
                <FormControlLabel
                    control={
                        <Checkbox
                            checked
                            onChange={(e: any) => console.log(e)}
                            name="active"
                            color="primary"
                        />
                    }
                    label={item.name}
                />
            )
        },
        {
            header: "Filter Title",
            accessor: 'filter_title',
        },
        {
            header: 'Filter Types',
            accessor: 'filter_types',
            content: (item: any) => (
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={item.filter_types}
                    label="Age"
                    onChange={() => console.log()}
                >
                    <MenuItem value="text">Text</MenuItem>
                    <MenuItem value="integer">Integer</MenuItem>
                </Select>
            )
        },
        {
            header: () => (
                (
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked
                                onChange={(e: any) => console.log(e)}
                                name="active"
                                color="primary"
                            />
                        }
                        label={langString('Searchable')}
                    />
                )
            ),
            accessor: 'searchable',
            content: (item: any) => (
                <Checkbox
                    onChange={(e: any) => console.log(e)}
                    name="active"
                    color="primary"
                />
            )
        },
        {
            header: () => (
                (
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked
                                onChange={(e: any) => console.log(e)}
                                name="active"
                                color="primary"
                            />
                        }
                        label={langString('Dependant')}
                    />
                )
            ),
            accessor: 'dependant',
            content: (item: any) => (
                <Checkbox
                    onChange={(e: any) => console.log(e)}
                    name="active"
                    color="primary"
                />
            )
        },
        {
            header: "Parent Attribute",
            accessor: 'parent_attribute',
            content: (item: any) => (
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={item.filter_types}
                    label="Age"
                    onChange={() => console.log()}
                >
                    <MenuItem value="text">Text</MenuItem>
                    <MenuItem value="integer">Integer</MenuItem>
                </Select>
            )
        },
        {
            header: 'Order',
            accessor: 'order'
        }
    ];

    return (
        <>
            <Grid item xs={12}>
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={4}>
                        <Typography variant="h5">Data Source Type</Typography>

                        <Autocomplete
                            multiple
                            id="checkboxes-tags-demo"
                            options={top100Films}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.title}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.title}
                                </li>
                            )}

                            renderInput={(params) => (
                                <TextField {...params} label="Data Source Type" />
                            )}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="h5">Data Source</Typography>
                        <Autocomplete
                            multiple
                            id="checkboxes-tags-demo"
                            options={top100Films}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.title}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.title}
                                </li>
                            )}

                            renderInput={(params) => (
                                <TextField {...params} label="Data Source" />
                            )}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <br />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked
                                    onChange={(e: any) => console.log(e)}
                                    name="active"
                                    color="primary"
                                />
                            }
                            label={langString('publish')}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked
                                    onChange={(e: any) => console.log(e)}
                                    name="active"
                                    color="primary"
                                />
                            }
                            label={langString('active')}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <DataSheetTable columns={columns1} rows={rows1} />
            </Grid>
            <Grid item xs={12}>
                <DataSheetTable columns={columns2} rows={rows2} />
            </Grid>
            <Grid item xs={12} alignItems="center">
                <Grid container justifyContent="center" spacing={2}>
                    <Grid item>
                        <Button onClick={(e: any) => setStep(1)} variant="contained" > Back</Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={closeCreateView} variant="contained" style={{ backgroundColor: 'orange' }}> Skip And Create Data Sheet</Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={closeCreateView} variant="contained" > Create Data Sheet</Button>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}