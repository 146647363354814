import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DateRangePicker from '@mui/lab/DateRangePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { gridSpacing } from 'store/constant';
import langString from 'utils/langString';

type InfoCardPropsType = {
    bgColor: string;
    title: string;
    value: number;
};

const InfoCard = ({ title, value, bgColor }: InfoCardPropsType) => (
    <Box sx={{ background: bgColor, height: 70, padding: 1, color: '#FFF', borderRadius: 2 }}>
        <Typography sx={{ fontSize: 12, fontWeight: 600 }}>{title}</Typography>
        <Typography sx={{ fontSize: 25, fontWeight: 600, textAlign: 'right', marginTop: 1 }}>{value}</Typography>
    </Box>
);

export default function DataSheetFilter({
    dateRange,
    setDateRange,
    published,
    setPublished,
    active,
    setActive,
    name,
    setName,
    openCreateView
}: any) {
    const currentProject = useSelector((state: any) => state.projects.currentProject);
    return (
        <>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12} sm={2}>
                    <InfoCard bgColor="#CB923E" title={langString('totalDataSheetUsers')} value={12} />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <InfoCard bgColor="#048698" title={langString('totalUsers')} value={15} />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateRangePicker
                            startText="Start"
                            endText="End"
                            value={dateRange}
                            // eslint-disable-next-line @typescript-eslint/no-implied-eval
                            onChange={(newValue) => setDateRange(newValue)}
                            renderInput={(startProps, endProps) => (
                                <>
                                    <TextField size="small" {...startProps} />
                                    <Box sx={{ mx: 2 }}> to </Box>
                                    <TextField size="small" {...endProps} />
                                </>
                            )}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={1}>
                    <TextField
                        size="small"
                        id="outlined-select-name"
                        fullWidth
                        label={langString('formname')}
                        value={name}
                        onChange={(event: any) => setName(event.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={1}>
                    <Button variant="contained" color="primary">
                        {langString('filter')}
                    </Button>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Button
                        disabled={currentProject?.isArchived}
                        variant="contained"
                        style={{ backgroundColor: 'orange' }}
                        onClick={openCreateView}
                    >
                        {langString('createDataSheet')}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}
