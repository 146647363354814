import langString from "utils/langString";

const Columns = [
    { header: langString('Full Name'), accessor: 'name' },
    { header: langString('Project'), accessor: 'address' },
    { header: langString('Organization'), accessor: 'organization' },
    { header: langString('Role'), accessor: 'role' },
    { header: langString('Email'), accessor: 'email' },
    { header: langString('Mobile No'), accessor: 'mobileNo' },
];


export default Columns;

export const data = [
    {
        id: 1,
        name: 'John Doe',
        address: 'Dhaka',
        organization: 'Organization 1',
        role: 'Admin',
        email: 'abc@gmail.com',
        mobileNo: '01700000000',
        checked: false
    },
    {
        id: 2,
        name: 'John Doe',
        address: 'Dhaka',
        organization: 'Organization 1',
        role: 'Admin',
        email: 'abcd@gmail.com',
        mobileNo: '01700000000',
        checked: false
    }
];


export const usersDemo = [
    {
        id: 1,
        type: 'user',
        fullName: 'John Doe',
        role: 'User',
        orgName: 'BRAC',
        projectName: 'BRAC Education',
        message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        date: '26, Jul 2021',
        time: '9: 27 AM',
        status: 'read',
        checked: false
    },
    {
        id: 2,
        type: 'system',
        fullName: 'John Doe',
        role: 'Admin',
        orgName: 'BRAC',
        projectName: 'BRAC Education',
        message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        date: '26, Jul 2021',
        time: '9: 27 AM',
        status: 'unread',
        checked: false
    },
];