import { Button, Grid } from "@mui/material";
import React, { useState } from "react";
import Form1 from "./Form1";
import Form2 from "./Form2";

export default function CreateDataSheet({ openCreateView, closeCreateView }: any) {

    const [step, setStep] = useState<any>(1);
    return (
        <Grid container spacing={2}>
            {step == 1 && <Form1 setStep={setStep} closeCreateView={closeCreateView} />}
            {step == 2 && <Form2 setStep={setStep} closeCreateView={closeCreateView} />}
        </Grid>
    );
}