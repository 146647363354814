import { Box, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { gridSpacing } from 'store/constant';
import { setAllFormSummary } from 'store/formsReducer';
import axiosServices from 'utils/axiosServices';
import { _deserailize } from 'utils/Deserialize';
import langString from 'utils/langString';
import { getDashboardCountURL } from 'utils/serverUrls';

type InfoCardPropsType = {
    bgColor: string;
    title: string;
    value: number;
};
const InfoCard = ({ title, value, bgColor }: InfoCardPropsType) => (
    <Grid item xs={12} sm={3}>
        <Box sx={{ background: bgColor, height: 100, padding: 2, color: '#FFF', borderRadius: 2 }}>
            <Typography sx={{ fontSize: 16, fontWeight: 600 }}>{title}</Typography>
            <Typography sx={{ fontSize: 30, fontWeight: 600, textAlign: 'right', marginTop: 1 }}>{value}</Typography>
        </Box>
    </Grid>
);
type ProjectFormsCardDataType = {
    projectID: number;
};
export default function ProjectFormsCards({ projectID }: ProjectFormsCardDataType) {
    const { allFormSummary } = useSelector((state: any) => state.form);
    const dispatch = useDispatch();

    const init = async () => {
        try {
            const response = await axiosServices.get(`${getDashboardCountURL}?projectId=${projectID}`);
            const data = await _deserailize(response.data);
            dispatch(
                setAllFormSummary({
                    totalActive: data[0].totalActive,
                    totalSubmission: data[0].totalSubmission,
                    totalUniqueSurveyors: data[0].totalUniqueSurveyors,
                    totalForms: data[0].totalForms
                })
            );
        } catch (error) {
            dispatch(setAllFormSummary(null));
        }
    };

    useEffect(() => {
        init();
    }, [projectID]);

    return (
        <Grid container spacing={gridSpacing}>
            <InfoCard bgColor="#CB923E" title={langString('totalforms')} value={allFormSummary?.totalForms} />
            <InfoCard bgColor="#048698" title={langString('totalsubmissions')} value={allFormSummary?.totalSubmission} />
            <InfoCard bgColor="#069BF5" title={langString('uniquesurveyors')} value={allFormSummary?.totalUniqueSurveyors} />
            <InfoCard bgColor="#29CB4C" title={langString('totalactiveforms')} value={allFormSummary?.totalActive} />
        </Grid>
    );
}
