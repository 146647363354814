// project imports
import MainLayout from 'layout/MainLayout';
import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import Feedback from 'views/Feedback';
import Forms from 'views/Forms';
import FormUpload from 'views/Forms/Upload';
import Profile from 'views/Profile';
import ProjectDetails from 'views/Projects/Details';
import FormProfile from 'views/Projects/Details/Forms/FormProfile';
import ProjectTeamMemberAdd from 'views/Projects/Details/Teams/Add';
// sample page routing
const Projects = Loadable(lazy(() => import('views/Projects')));
const RoleManagement = Loadable(lazy(() => import('views/TeamManagement/RoleManagement')));
const UserManagement = Loadable(lazy(() => import('views/TeamManagement/UserManagement')));
const FormDetails = Loadable(lazy(() => import('views/Projects/Details/Forms/FormProfile/Details')));
const UserFormData = Loadable(lazy(() => import('views/Projects/Details/Forms/FormProfile/UserFormData')));
const FormSettings = Loadable(lazy(() => import('views/Projects/Details/Forms/FormProfile/Settings')));
const NewSubmission = Loadable(lazy(() => import('views/Projects/Details/Forms/FormProfile/NewSubmission')));
const Organization = Loadable(lazy(() => import('views/TeamManagement/OrgManagement')));
const Notifications = Loadable(lazy(() => import('views/Notifications')));
const Permission = Loadable(lazy(() => import('views/Permission')));
const Category = Loadable(lazy(() => import('views/MasterData/Category')));
const Item = Loadable(lazy(() => import('views/MasterData/Item')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),

    children: [
        {
            path: '/',
            element: <Projects />
        },
        {
            path: '/project/:id',
            element: <ProjectDetails />
        },
        {
            path: '/project/:id?tabIndex=:tabIndex',
            element: <ProjectDetails />
        },
        {
            path: '/project/:id/add-team-member/',
            element: <ProjectTeamMemberAdd />
        },
        {
            path: '/forms/',
            element: <Forms />
        },
        {
            path: '/feedback',
            element: <Feedback />
        },
        {
            path: '/profile',
            element: <Profile />
        },
        {
            path: '/roles',
            element: <RoleManagement />
        },
        {
            path: '/organizations',
            element: <Organization />
        },
        {
            path: '/users',
            element: <UserManagement />
        },
        {
            path: '/project/:projectId/form/:formId/:xformId',
            element: <FormProfile />
        },
        {
            path: '/project/:projectId/form/:formId/:xformId/details',
            element: <FormDetails />
        },
        {
            path: '/project/:projectId/form/:formId/data/:userId',
            element: <UserFormData />
        },
        {
            path: '/project/:projectId/form/:formId/settings',
            element: <FormSettings />
        },
        {
            path: '/project/:projectId/form/:formId/new-submission',
            element: <NewSubmission />
        },
        {
            path: '/form-upload',
            element: <FormUpload />
        },
        {
            path: '/notifications',
            element: <Notifications />
        },
        {
            path: '/permission',
            element: <Permission />
        },
        {
            path: '/category',
            element: <Category />
        },
        {
            path: '/item/:title/:id',
            element: <Item />
        }
    ]
};

export default MainRoutes;
