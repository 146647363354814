import { Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentUsers, setUsers } from 'store/formsReducer';

export default function TableUser() {
    const { users, currentState } = useSelector((state: any) => state?.form?.importForm);
    const dispatch = useDispatch();

    const handleAllSelect = (e: any) => {
        if (e.target.checked) {
            const cloneUserList = _.cloneDeep(users);
            cloneUserList.map((item: any) => {
                item.isChecked = e.target.checked;
            });

            dispatch(setUsers(cloneUserList));
            dispatch(setCurrentUsers(users));
        } else {
            const cloneUserList = _.cloneDeep(users);
            cloneUserList.map((item: any) => {
                item.isChecked = e.target.checked;
            });

            dispatch(setCurrentUsers([]));
            dispatch(setUsers(cloneUserList));
        }
    };

    const handleOnCheck = (e: any, user: any) => {
        if (e.target.checked) {
            const cloneUserList = _.cloneDeep(users);
            cloneUserList.map((item: any) => {
                if (item.id === user.id) {
                    if (e.target.checked) {
                        item.isChecked = e.target.checked;
                    }
                }
            });
            dispatch(setCurrentUsers([...currentState?.users, user]));
            dispatch(setUsers(cloneUserList));
        } else {
            const cloneUserList = _.cloneDeep(users);
            const currentUsers = _.cloneDeep(currentState?.users);
            cloneUserList.map((item: any) => {
                if (item.id === user.id) {
                    item.isChecked = e.target.checked;
                }
            });
            const unCheckUser = currentUsers.filter((itm: any) => itm.id !== user.id);
            dispatch(setCurrentUsers(unCheckUser));
            dispatch(setUsers(cloneUserList));
        }
    };
    if (users.length === 0) return null;
    return (
        <TableContainer>
            <Typography variant="h3" gutterBottom textAlign="center">
                User Table
            </Typography>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell style={{ border: '1px solid lightgrey' }}>
                            {' '}
                            <Checkbox onChange={(e) => handleAllSelect(e)} />
                            Username
                        </TableCell>

                        <TableCell style={{ border: '1px solid lightgrey' }}>Role</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map((user: any, index: number) => (
                        <TableRow key={user?.id}>
                            <TableCell scope="row" style={{ border: '1px solid lightgrey' }}>
                                <Checkbox checked={user?.isChecked} onChange={(e) => handleOnCheck(e, user)} />
                                {`${user?.firstName ? user?.firstName : ''} ${user?.lastName ? user?.lastName : ''}`}
                            </TableCell>

                            <TableCell style={{ border: '1px solid lightgrey' }}>
                                {user?.roles?.[0].name ? user?.roles?.[0].name : ''}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
