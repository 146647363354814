import { useEffect, useState } from 'react';
import { Button, Grid, MenuItem, TextField } from '@mui/material';
// import { Deserializer } from 'jsonapi-serializer';
import { gridSpacing } from 'store/constant';
import langString from 'utils/langString';
import { OrganizationsData, RolesData } from 'utils/demoData';

type ProjectReportsFilterPropsType = {
    organization: number;
    setOrganization: (value: number) => void;
    role: number;
    setRole: (value: number) => void;
    name: string;
    setName: (value: string) => void;
};
export default function ProjectReportsFilter({
    organization,
    setOrganization,
    role,
    setRole,
    name,
    setName
}: ProjectReportsFilterPropsType) {
    const [organizations, setOrganizations] = useState<any[]>([]);
    const [roles, setRoles] = useState<any[]>([]);

    useEffect(() => {
        const init = async () => {
            setOrganizations(OrganizationsData);
            setRoles(RolesData);
        };
        init();
    }, []);

    return (
        <>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12} sm={3}>
                    <TextField
                        size="small"
                        id="outlined-select-organization"
                        select
                        fullWidth
                        label={langString('organization')}
                        value={organization}
                        onChange={(event: any) => setOrganization(event.target.value)}
                    >
                        <MenuItem value={0}>{langString('selectone')}</MenuItem>
                        {organizations &&
                            organizations.map((option: any) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextField
                        size="small"
                        id="outlined-select-role"
                        select
                        fullWidth
                        label={langString('role')}
                        value={role}
                        onChange={(event: any) => setRole(event.target.value)}
                    >
                        <MenuItem value={0}>{langString('selectone')}</MenuItem>
                        {roles &&
                            roles.map((option: any) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextField
                        size="small"
                        id="outlined-select-name"
                        fullWidth
                        label={langString('name')}
                        value={name}
                        onChange={(event: any) => setName(event.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Button variant="contained" color="primary">
                        {langString('filter')}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}
