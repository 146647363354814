import { Button, Grid, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// project imports
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { checkNullInfo } from 'utils/Helpers';

// Props type
export type ColumnType = {
    header: string;
    accessor: string;
    content?: any;
};

type TableProps = {
    columns: ColumnType[];
    rows: any[];
    count?: number;
    page?: number;
    rowsPerPage?: number;
    setPage?: (value: number) => void;
    setRowsPerPage?: (value: number) => void;
    link?: string;
    linkID?: string;
    idField?: string;
    statusField?: string;
    updateStatus?: (id: string) => void;
    showSL?: boolean;
};

// ==============================|| TABLE - BASIC ||============================== //

export default function BasicDataTable({
    columns,
    rows,
    count,
    page,
    rowsPerPage,
    link,
    linkID,
    setPage,
    setRowsPerPage,
    idField,
    statusField,
    updateStatus,
    showSL
}: TableProps) {
    const navigate = useNavigate();
    const classes = useStyle();
    const categories = useSelector((state: any) => state.category.categories);
    console.log('rows\n\n\n\n', rows);
    const handleChangePage = (event: any, newPage: number) => {
        if (setPage) {
            setPage(newPage);
        }
    };

    const renderCell = (item: any, column: any) => {
        // console.log('item', item);
        if (column.content) {
            return column.content(item, categories);
        }
        return <Typography>{checkNullInfo(item[column.accessor])}</Typography>;
    };

    const pageStatus = () => {
        if (count && rowsPerPage && page) {
            let start = 1;
            let end = count;
            const totalPage = Math.ceil(count / rowsPerPage);
            if (page > 1) {
                start = (page - 1) * rowsPerPage + 1;
            }
            if (page < totalPage) {
                end = start + rowsPerPage - 1;
            }
            return `Showing ${start} to ${end} of ${count} results.`;
        }
        return '';
    };

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <MainCard content={false}>
                    <TableContainer>
                        <Table sx={{ minWidth: 350 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    {showSL && (
                                        <TableCell align="center" sx={{ width: '150px', overflowWrap: 'break-word' }}>
                                            <Typography variant="subtitle1">SL</Typography>
                                        </TableCell>
                                    )}
                                    {columns.map((column: any, index: number) => (
                                        <TableCell key={index} align="center" sx={{ width: '150px', overflowWrap: 'break-word' }}>
                                            <Typography variant="subtitle1">{column.header}</Typography>
                                        </TableCell>
                                    ))}
                                    {statusField && idField && updateStatus && (
                                        <TableCell align="center" sx={{ width: '150px', overflowWrap: 'break-word' }}>
                                            <Typography variant="subtitle1">Update Status</Typography>
                                        </TableCell>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.length ? (
                                    rows.map((row: any, i: number) => (
                                        <TableRow
                                            key={i}
                                            className={classes.rowsHover}
                                            onClick={() => (linkID ? navigate(`${link + row[linkID]}/`) : '')}
                                        >
                                            {showSL && (
                                                <TableCell align="center" sx={{ width: '150px', overflowWrap: 'break-word' }}>
                                                    <Typography variant="subtitle1" sx={{ overflowWrap: 'break-word' }}>
                                                        {i + 1}
                                                    </Typography>
                                                </TableCell>
                                            )}
                                            {columns.map((column: ColumnType, k: number) => (
                                                <TableCell align="center" key={k} sx={{ width: '150px', overflowWrap: 'break-word' }}>
                                                    {renderCell(row, column)}
                                                </TableCell>
                                            ))}
                                            {statusField && idField && updateStatus && (
                                                <TableCell align="center" sx={{ width: '150px', overflowWrap: 'break-word' }}>
                                                    <Button
                                                        variant="contained"
                                                        color={row[statusField] ? 'success' : 'primary'}
                                                        size="small"
                                                        onClick={() => updateStatus(row[idField])}
                                                    >
                                                        {row[statusField] ? 'Cancel Approval' : 'Approve'}
                                                    </Button>
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow className={classes.rowsHover}>
                                        <TableCell align="center" colSpan={showSL ? columns.length + 1 : columns.length}>
                                            <h3>No Data Found</h3>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {page && count && rowsPerPage ? (
                        <Grid container spacing={3} alignItems="center" direction="row-reverse" style={{ padding: '1.5%' }}>
                            <Grid item>
                                <Pagination
                                    count={Math.ceil(count / rowsPerPage)}
                                    page={page}
                                    onChange={handleChangePage}
                                    showFirstButton
                                    showLastButton
                                />
                            </Grid>
                            <Grid item>
                                {' '}
                                <p>{pageStatus()}</p>{' '}
                            </Grid>
                        </Grid>
                    ) : (
                        ''
                    )}
                </MainCard>
            </Grid>
        </Grid>
    );
}

const useStyle = makeStyles({
    rowsHover: {
        borderBottom: '1px solid #DDD',
        '&:hover': {
            backgroundColor: '#FFE'
        }
    }
});
