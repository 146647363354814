import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import { Grid, Typography } from '@mui/material';
import CustomSubCard from 'components/Card/CustomSubCard';
import { useState } from 'react';
import Loading from 'ui-component/Loding';
import { checkNullInfo } from 'utils/Helpers';
import langString from 'utils/langString';
import ProjectSettings from '../Settings';
import ProjectEdit from '../Settings/Edit';
import { ProjectType } from './Constants';

type ProjectDescriptionPropsType = {
    project?: ProjectType | null;
    refresh: () => void;
};
export default function ProjectDescription({ project, refresh }: ProjectDescriptionPropsType) {
    const [open, setOpen] = useState(false);

    if (typeof project === 'undefined') {
        return <Loading />;
    }
    if (project === null) {
        return (
            <Typography sx={{ marginX: 'auto', marginY: 2 }} variant="h3">
                Data not found
            </Typography>
        );
    }

    return (
        <CustomSubCard
            title={
                <Grid spacing={1} container justifyContent="flex-start" alignItems="center">
                    <Grid item>
                        <CalendarTodayOutlinedIcon />
                    </Grid>
                    <Grid item>
                        {langString('startdate')}: {project?.startDate}
                    </Grid>
                    <Grid item sx={{ marginLeft: 4 }}>
                        <CalendarTodayOutlinedIcon />
                    </Grid>
                    <Grid item>
                        {langString('enddate')}: {project.endDate}
                    </Grid>
                </Grid>
            }
            secondary={
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" marginTop={0.5}>
                    <Grid item>
                        <ProjectSettings
                            project={project}
                            title={project.name}
                            published={project.isPublished}
                            active={project.isActive}
                            archived={project.isArchived}
                            refresh={refresh}
                        />
                    </Grid>
                    <Grid item>
                        <ProjectEdit projectId={project.id} refresh={refresh} open={open} setOpen={setOpen} />
                    </Grid>
                </Grid>
            }
            sx={{ border: '1px solid #DDD !important', borderRadius: 1 }}
        >
            <Typography variant="body1" sx={{ padding: 2 }}>
                {checkNullInfo(project.description)}
            </Typography>
        </CustomSubCard>
    );
}
