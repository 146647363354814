import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import _ from 'lodash';

export function dateTimeDuration(date: string) {
    const dateTime = dayjs(date);
    return `Hour: ${dateTime.format('h')}  Minute: ${dateTime.minute()} ${dateTime.format('A')}`;
}

export function isPrevDate(date: string) {
    dayjs.extend(relativeTime);
    return dayjs().isBefore(dayjs(date));
}

export function dd_mm_yyyy(today: any): any {

    if (today == '' || today === null || today === undefined) return '';

    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1;
    let dd = today.getDate();

    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;

    today = `${dd}-${mm}-${yyyy}`;
    return today;
}

export function yyyy_mm_dd(today: any): any {

    console.log('today: ', today);
    if (today == '' || today === null || today === undefined) return '';

    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1;
    let dd = today.getDate();

    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;

    today = `${yyyy}-${mm}-${dd}`;
    return today;
}