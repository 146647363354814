import { Button, Grid, TextareaAutosize, TextField, Typography } from "@mui/material";
import React from "react";
import { top100Films } from "./constants";
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function Form1({
    closeCreateView,
    setStep,
}: any) {

    return (
        <>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h5">Data Sheet Name</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Data Sheet Name" />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5">Description</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextareaAutosize
                            style={{ width: '100%' }}
                            aria-label="minimum height"
                            minRows={3}
                            placeholder="Minimum 3 rows"

                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5">Access</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <Autocomplete
                                    multiple
                                    id="checkboxes-tags-demo"
                                    options={top100Films}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option.title}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.title}
                                        </li>
                                    )}

                                    renderInput={(params) => (
                                        <TextField {...params} label="Organization" placeholder="Favorites" />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Autocomplete
                                    multiple
                                    id="checkboxes-tags-demo"
                                    options={top100Films}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option.title}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.title}
                                        </li>
                                    )}

                                    renderInput={(params) => (
                                        <TextField {...params} label="Roles" placeholder="Favorites" />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Autocomplete
                                    multiple
                                    id="checkboxes-tags-demo"
                                    options={top100Films}
                                    disableCloseOnSelect
                                    getOptionLabel={(option: any) => option.title}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.title}
                                        </li>
                                    )}

                                    renderInput={(params: any) => (
                                        <TextField {...params} label="Users" placeholder="Favorites" />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>


            <Grid item xs={12} alignItems="center">
                <Grid container justifyContent="center" spacing={2}>
                    <Grid item>
                        <Button onClick={closeCreateView} variant="contained" > Back</Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={(e: any) => setStep(2)} variant="contained" > Next</Button>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}