import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CloseIcon from '@mui/icons-material/Close';
import {
    Autocomplete,
    Box,
    Button,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputLabel,
    Modal,
    OutlinedInput,
    TextField,
    Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useSnackbar from 'hooks/useSnackbar';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { gridSpacing } from 'store/constant';
import { resetImportForm, setCurrentFormName, setCurrentImportForm, setImportFormList, setOrganizations, setUsers } from 'store/formsReducer';
import axiosFormServices from 'utils/axiosFormServices';
import axiosServices from 'utils/axiosServices';
import { _deserailize } from 'utils/Deserialize';
import FormValidators from 'utils/FormValidators';
import langString from 'utils/langString';
import { formsCloneURL, getProjectOrganizationsURL, templateListURL } from 'utils/serverUrls';
import ImportFormCascading from './ImportFormCascading';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    borderRadius: '10px !important',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 3,
    overflow: 'auto',
    height: 'calc(100vh - 64px)'
};

type ProjectFormsType = {
    open: boolean;
    setOpen: (open: boolean) => void;
    refreshData: () => void;
};

export default function ProjectFormImport({ open, setOpen, refreshData }: ProjectFormsType) {
    const theme = useTheme();
    const [errors, setErrors] = useState<any>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { importForm } = useSelector((state: any) => state?.form);
    const projectId = useParams();
    const snackbarMsg = useSnackbar();
    const dispatch = useDispatch();
    const importFormSubmit = async () => {
        setIsSubmitting(true);
        const allErrors = FormValidators(
            ['name', 'formItem', 'organization'],
            [importForm?.currentState?.formName, importForm?.currentState?.form, importForm?.currentState?.organizations]
        );

        if (allErrors.valid) {
            if (projectId?.id) {
                const form = {
                    id_string: importForm?.currentState?.form?.idString,
                    form_title: importForm?.currentState?.formName,
                    project_id: parseInt(projectId?.id, 10),
                    organizations: [...importForm?.currentState?.organizations.map((org: any) => parseInt(org?.orgId, 10))],
                    roles: [...importForm?.currentState?.roles.map((roleItem: any) => parseInt(roleItem.id, 10))],
                    users: [...importForm?.currentState?.users.map((userItem: any) => parseInt(userItem.id, 10))]
                };
                try {
                    await axiosFormServices.post(`${formsCloneURL}`, form);
                    snackbarMsg('Form imported successful', 'success');

                    setOpen(false);
                    setIsSubmitting(false);
                    refreshData();
                    onClose();
                } catch (error) {
                    snackbarMsg('Form not imported', 'error');
                }

                setOpen(false);
            }
        } else {
            setErrors(allErrors);
        }
        setIsSubmitting(false);
    };

    useEffect(() => {
        (async () => {
            try {
                const formListRes = await axiosServices.post(`${templateListURL}`);
                const formList = await _deserailize(formListRes.data);

                dispatch(setImportFormList(formList));
            } catch (e) {
                console.log(e);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const orgsRes = await axiosServices.get(`${getProjectOrganizationsURL}/?id=${projectId.id}`);
                const orgsList = await _deserailize(orgsRes.data);

                dispatch(setOrganizations(orgsList));
            } catch (e) {
                console.log(e);
            }
        })();
    }, []);

    const onFormChange = (event: any, value: any) => {
        dispatch(setCurrentImportForm(value));
        dispatch(setCurrentFormName(value?.title));
    };

    const onClose = () => {
        dispatch(resetImportForm());
        dispatch(setUsers([]));
        setOpen(false);
    }

    return (
        <>
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ borderBottom: '1px solid #000', marginBottom: 3 }}
                    >
                        <Grid item xs={6}>
                            <Typography variant="h4" onClick={onClose}>
                                <AddCircleIcon /> {langString('importform')}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <IconButton
                                color="secondary"
                                onClick={onClose}
                                aria-label="closeModal"
                                component="span"
                                sx={{ color: 'black', float: 'right' }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>

                    <Grid container alignItems="center" spacing={gridSpacing} justifyContent="space-between" sx={{ padding: 2 }}>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="h6">{langString('formname')}</Typography>
                            <FormControl
                                fullWidth
                                error={Boolean(errors && errors.name?.required)}
                                sx={{ ...theme.typography.customInput }}
                            >
                                <InputLabel htmlFor="outlined-adornment-name">{`${langString('formname')} *`}</InputLabel>
                                <OutlinedInput
                                    size="small"
                                    inputProps={{}}
                                    id="name"
                                    name="name"
                                    type="text"
                                    value={importForm?.currentState?.formName}
                                    onChange={(event: any) => dispatch(setCurrentFormName(event.target.value))}
                                    sx={{ ...theme.typography.customInput }}
                                />

                                {Boolean(errors && errors.name?.required) && <FormHelperText error>{errors.name?.message}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="h6">{langString('selectform')}</Typography>
                            <FormControl
                                fullWidth
                                error={Boolean(errors && errors.formItem?.required)}
                                sx={{ ...theme.typography.customInput }}
                            >
                                <Autocomplete
                                    disablePortal
                                    id="checkboxes-tags-form"
                                    options={importForm?.forms}
                                    value={importForm?.currentState?.form}
                                    getOptionLabel={(option: any) => option.title}
                                    onChange={onFormChange}
                                    style={{ width: '100%' }}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                />

                                {Boolean(errors && errors.formItem?.required) && (
                                    <FormHelperText error>{errors.formItem?.message}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                    </Grid>
                    <ImportFormCascading />
                    <Grid marginTop={2} container alignItems="center" justifyContent="space-between">
                        <Grid item xs={12} textAlign="center">
                            <Button
                                sx={{
                                    borderColor: theme.palette.error.dark,
                                    marginRight: 2
                                }}
                                onClick={onClose}
                                variant="contained"
                                color="inherit"
                            >
                                {langString('cancel')}
                            </Button>
                            <Button disabled={isSubmitting} color="primary" variant="contained" onClick={() => importFormSubmit()}>
                                {langString('import')}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </>
    );
}
