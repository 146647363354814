import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { ProjetcsData } from 'utils/demoData';
import ProjectModuleAdd from './Add';

type ProjectDescriptionPropsType = {
    projectID: number;
};
export default function ProjectModules({ projectID }: ProjectDescriptionPropsType) {
    useEffect(() => {
        const project = ProjetcsData.find((pro: any) => pro.id === projectID);
        if (project) {
            console.log(project);
        }
    }, [projectID]);

    return (
        <Grid container direction="row" justifyContent="flex-start" alignItems="center" marginTop={0.5}>
            <Grid item>
                <ProjectModuleAdd projectID={projectID} />
            </Grid>
        </Grid>
    );
}
