import { Box, Grid, IconButton, Modal, TextField, Typography } from '@mui/material'
import React from 'react'
import { modalStyle } from './style'
import CloseIcon from '@mui/icons-material/Close';
import { IconBell } from '@tabler/icons';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DateRangeIcon from '@mui/icons-material/DateRange';

export default function NotificationModal({ onClose, open, user }: any) {
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>
                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ borderBottom: '1px solid #000', marginBottom: 3 }}
                >
                    <Grid item xs={6} display='flex' alignItems='center' color='#0288d1'>
                        <IconBell />
                        <Typography variant="h4" color="#0288d1">
                            Notification
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <IconButton
                            color="secondary"
                            onClick={onClose}
                            aria-label="closeModal"
                            component="span"
                            sx={{ color: 'black', float: 'right' }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid container justifyContent='space-between'>
                    <Grid item xs={4}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Typography variant='h5'>Project: </Typography>
                            <Typography variant='body1'>{user?.projectName}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Typography variant='h5'>Form: </Typography>
                            <Typography variant='body1'>{user?.fullName}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Typography variant='h5'>Role: </Typography>
                            <Typography variant='body1'>{user?.role}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Typography variant='h5'>Organization: </Typography>
                            <Typography variant='body1'>{user?.orgName}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <DateRangeIcon fontSize='small' />
                            <Typography variant='body1'>{user?.date}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <AccessTimeIcon fontSize='small' />
                            <Typography variant='body1'>{user?.time}</Typography>
                        </Box>
                    </Grid>
                </Grid>
                <br />
                <Grid container>
                    <Grid item xs={12}>
                        <TextField fullWidth type='text' multiline rows={7} value={user?.message} />
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}
