/* eslint-disable consistent-return */
import axiosServices from 'utils/axiosServices';
import { projectAddURL, projectGetURL, projectUpdateURL } from 'utils/serverUrls';
import lodash from 'lodash';
import { yyyy_mm_dd } from 'utils/date';
import { errMsg } from 'utils/Helpers';
import { _deserailize } from 'utils/Deserialize';
import * as yup from 'yup';

export const validationSchema = yup.object().shape({
    name: yup.string().required('Name is Required'),
    startDate: yup
        .date()
        .default(() => new Date())
        .nullable()
        .required('Start Date is Required'),
    endDate: yup
        .date()
        .required('End Date is Required')
        .nullable()
        .when('startDate', (startDate, schema) => {
            if (startDate) {
                return schema.min(startDate);
            }
        }),

    description: yup.string().required('Description is Required'),
    partner: yup.mixed().test('partner', 'Partner is Required', (checkbox: any) => {
        let check = false;
        if (checkbox) {
            for (const [key, value] of Object.entries(checkbox)) {
                if (key && value && value !== '') {
                    check = true;
                    break;
                }
            }
        }
        return check;
    })
});

export const updateProject = async (values: any, snackbarMsg: any) => {
    const projectValues = lodash.cloneDeep(values);
    delete projectValues.partner;
    delete projectValues.projectImage;
    projectValues.startDate = yyyy_mm_dd(projectValues.startDate);
    projectValues.endDate = yyyy_mm_dd(projectValues.endDate);

    const formData = new FormData();
    formData.append('project', JSON.stringify(projectValues));
    formData.append('projectPartner', JSON.stringify(values.partner.map((org: any) => ({ orgId: org.id }))));
    values.projectImage && formData.append('projectImage', values.projectImage);

    try {
        await axiosServices.put(`${projectUpdateURL}`, formData);
        snackbarMsg('Project updated successfully');
        return await new Promise((r, _) => r(''));
    } catch (ex: any) {
        snackbarMsg(errMsg(ex), 'error');
        return await new Promise((r, _) => r(''));
    }
};

export const getProjectById = async (id: number | string, snackbarMsg: any) => {
    try {
        const response = await axiosServices.get(`${projectGetURL}`, { params: { id } });
        const data = await _deserailize(response.data);
        return await new Promise((r, _) => r(data));
    } catch (ex) {
        snackbarMsg(errMsg(ex), 'error');
        return await new Promise((r, _) => r({}));
    }
};

export const createProject = async (values: any, snackbarMsg: any) => {
    const projectValues = lodash.cloneDeep(values);
    delete projectValues.partner;
    delete projectValues.projectImage;
    projectValues.startDate = yyyy_mm_dd(projectValues.startDate);
    projectValues.endDate = yyyy_mm_dd(projectValues.endDate);

    const formData = new FormData();
    formData.append('project', JSON.stringify(projectValues));
    formData.append('projectPartner', JSON.stringify(values.partner.map((org: any) => ({ orgId: org.id }))));
    formData.append('projectImage', values.projectImage);

    try {
        await axiosServices.post(`${projectAddURL}`, formData);
        snackbarMsg('Project added successfully');
        return await new Promise((r, _) => r({}));
    } catch (ex: any) {
        snackbarMsg(errMsg(ex), 'error');
        return await new Promise((r, _) => r({}));
    }
};
