import { Grid } from "@mui/material";
import ConfirmButton from "components/common/ConfirmButton";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DateRange } from "types";
import MainCard from "ui-component/cards/MainCard";
import DataSheetFilter from "./Filter";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import BasicDataTable from "components/Table/BasicDataTable";
import CreateDataSheet from "./Create";

export default function DataSheet(props: any) {
    const [rows, setRows] = useState<any[]>([]);
    const [dateRange, setDateRange] = useState<any>([null, null]);
    const [published, setPublished] = useState(false);
    const [active, setActive] = useState<boolean>(false);
    const [name, setName] = useState<any>("");
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const { formId } = useParams();
    const navigate = useNavigate();
    const [createView, setCreateView] = useState<boolean>(false);

    const columns: any = [
        { header: 'Data Frame Name', accessor: 'username' },
        { header: 'Created On', accessor: 'organization' },
        { header: 'Last Edited On', accessor: 'role' },
        {
            header: 'Action',
            accessor: 'action',
            content: (item: any) => (
                <ConfirmButton
                    variant="outlined"
                    buttonText={<DeleteForeverIcon />}
                    subTitle={`Delete Team Member: ${item.username}`}
                    confirmed={() => console.log('delete stuff')}
                />
            )
        }
    ];


    return (
        <MainCard>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12}>
                    <DataSheetFilter
                        dateRange={dateRange}
                        setDateRange={(value: any) => setDateRange(value)}
                        published={published}
                        setPublished={(value: boolean) => setPublished(value)}
                        active={active}
                        setActive={(value: boolean) => setActive(value)}
                        name={name}
                        setName={(value: string) => setName(value)}
                        openCreateView={(e: any) => setCreateView(true)} />
                </Grid>
                <Grid item xs={12}>
                    {createView
                        ? <CreateDataSheet
                            closeCreateView={(e: any) => setCreateView(false)}
                            openCreateView={(e: any) => setCreateView(true)} />
                        : <BasicDataTable
                            rows={rows}
                            columns={columns}
                            count={count}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            setPage={(value: number) => setPage(value)}
                            setRowsPerPage={(value: number) => setRowsPerPage(value)}
                            showSL
                        />}
                </Grid>
            </Grid>
        </MainCard>
    );
}