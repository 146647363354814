import { Serializer } from "jsonapi-serializer";
import lodash from "lodash";
import axiosServices from "utils/axiosServices";
import { _deserailize } from "utils/Deserialize";
import { errMsg } from "utils/Helpers";
import * as yup from 'yup';
import { organizationCreateURL, organizationDeleteURL, organizationListURL, organizationUpdateURL } from "utils/serverUrls";

export const validationSchema = yup.object({
    name: yup.string().required('Name is a required field'),
    address: yup.string().required('Address is a required field'),
    organizationSize: yup
        .number()
        .positive('Organization Size must be a positive number')
        .required('Organization Size is a required field'),
    parentOrgId: yup.string().required('Parent Org is a required field')
})

export const fetchOrgList = async (orgId: number, currentPage: number, pageSize: number, snackbarMsg: any) => {

    const params = {
        orgId,
        pageSize,
        currentPage,
    };
    try {
        const response: any = await axiosServices.get(`${organizationListURL}`, { params });
        const data = await _deserailize(response.data);
        return await new Promise((r, _) => r({ data, count: response?.data?.meta?.count }))
    } catch (ex) {
        snackbarMsg(errMsg(ex), 'error');
        return await new Promise((r, _) => r({ data: [], count: 0 }));
    }
}

export const deleteOrg = async (id: number | string, snackbarMsg: any) => {
    try {
        await axiosServices.delete(`${organizationDeleteURL}`, { params: { id } });
        snackbarMsg('Organization delete successful');
        return await new Promise((r, _) => r({}))
    } catch (ex) {
        snackbarMsg(errMsg(ex), 'error')
        return await new Promise((r, _) => r({}))
    }
}

export const addOrg = async (values: any, snackbarMsg: any) => {

    const serializedData = new Serializer('Org', {
        keyForAttribute: 'camelCase',
        attributes: Object.keys(values)
    }).serialize(values);

    try {
        await axiosServices.post(`${organizationCreateURL}`, serializedData);
        snackbarMsg('Organization created successfully.');
        return await new Promise((r, _) => r({}));
    } catch (ex: any) {
        snackbarMsg(errMsg(ex), 'error');
        return await new Promise((r, _) => r({}));
    }
}

export const editOrg = async (values: any, snackbarMsg: any) => {
    values = lodash.cloneDeep(values);
    delete values.parentOrg;
    if (lodash.isEmpty(values.parentOrgId)) delete values.parentOrgId;

    const serializedData = new Serializer('Org', {
        keyForAttribute: 'camelCase',
        attributes: Object.keys(values)
    }).serialize(values);

    try {
        await axiosServices.put(`${organizationUpdateURL}`, serializedData);
        snackbarMsg('Organization updated successfully.');
        return await new Promise((r, _) => r({}));

    } catch (ex: any) {
        snackbarMsg(errMsg(ex), 'error');
        return await new Promise((r, _) => r({}))
    }
}

export default {};