import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Box, Button, Grid } from '@mui/material';
import ConfirmButton from 'components/common/ConfirmButton';
import BasicDataTable, { ColumnType } from 'components/Table/BasicDataTable';
import useSnackbar from 'hooks/useSnackbar';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'ui-component/Loader';
import { ProjectReportsData } from 'utils/demoData';
import langString from 'utils/langString';
import ProjectReportsFilter from './Filter';

type ProjectReportsPropsType = {
    projectID: number;
};
export default function ProjectReports({ projectID }: ProjectReportsPropsType) {
    const [loading, setLoading] = useState(false);
    const [organization, setOrganization] = useState(0);
    const [role, setRole] = useState(0);
    const [name, setName] = useState('');
    const [rows, setRows] = useState<any[]>([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const snackbarMsg = useSnackbar();
    const currentProject = useSelector((state: any) => state.projects.currentProject);
    const dispatch = useDispatch();
    const columns: ColumnType[] = [
        { header: langString('reportname'), accessor: 'name' },
        { header: langString('organization'), accessor: 'organization' },
        {
            header: 'Action',
            accessor: 'action',
            content: (item: any) => (
                <ConfirmButton
                    variant="outlined"
                    buttonText={<DeleteForeverIcon />}
                    subTitle={`Delete Report: ${item.name}`}
                    confirmed={() => deleteListItem(item.id)}
                />
            )
        }
    ];

    const deleteListItem = async (id: number) => {
        if (id) {
            setLoading(true);
            try {
                snackbarMsg('Member deleted successfully.');

                const allRows = [...rows];
                const filtered = allRows.filter((item: any) => item.id !== id);
                setRows(filtered);
                setLoading(false);
            } catch (error: any) {
                setLoading(false);
                snackbarMsg(error, 'error');
            }
        }
    };

    useEffect(() => {
        const init = async () => {
            setLoading(true);
            try {
                const allReports = ProjectReportsData;
                if (allReports) {
                    setRows(allReports);
                    setCount(allReports.length);
                    setLoading(false);
                }
            } catch (error) {
                setLoading(false);
                console.log(error);
            }
        };
        init();
    }, [page, rowsPerPage, organization, role, name]);

    return (
        <>
            {loading && <Loader />}
            <Grid container>
                <Grid item xs={12} sm={10}>
                    <ProjectReportsFilter
                        organization={organization}
                        setOrganization={(value: number) => setOrganization(value)}
                        role={role}
                        setRole={(value: number) => setRole(value)}
                        name={name}
                        setName={(value: string) => setName(value)}
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Button
                        disabled={currentProject?.isArchived}
                        variant="contained"
                        color="success"
                        sx={{ float: 'right', color: '#fff' }}
                    >
                        {langString('importreport')}
                    </Button>
                </Grid>
            </Grid>

            <Box sx={{ marginTop: 2 }}>
                <BasicDataTable
                    rows={rows}
                    columns={columns}
                    count={count}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    setPage={(value: number) => setPage(value)}
                    setRowsPerPage={(value: number) => setRowsPerPage(value)}
                    showSL
                />
            </Box>
        </>
    );
}
