import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, FormControl, FormHelperText, Grid, IconButton, MenuItem, Modal, Select, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { gridSpacing } from 'store/constant';
import { ModulesTypeData, OrganizationsData, RolesData, TeamMembersData } from 'utils/demoData';
import FormValidators from 'utils/FormValidators';
import langString from 'utils/langString';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 3
};

type ProjectModuleAddPropsType = {
    projectID: number;
};
export default function ProjectModuleAdd({ projectID }: ProjectModuleAddPropsType) {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [moduleType, setModuleType] = useState(0);
    const [organization, setOrganization] = useState(0);
    const [organizations, setOrganizations] = useState<any[]>([]);
    const [role, setRole] = useState(0);
    const [roles, setRoles] = useState<any[]>([]);
    const [user, setUser] = useState(0);
    const [users, setUsers] = useState<any[]>([]);
    const [moduleTypes, setModuleTypes] = useState<any[]>([]);
    const [errors, setErrors] = useState<any>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const currentProject = useSelector((state: any) => state.projects.currentProject);
    const createModule = () => {
        console.log(projectID);
        setIsSubmitting(true);
        const allErrors = FormValidators(['moduleType', 'organization'], [moduleType, organization]);
        if (allErrors.valid) {
            setOpen(false);
        } else {
            setErrors(allErrors);
        }
        setIsSubmitting(false);
    };

    useEffect(() => {
        const allModuleTypes = ModulesTypeData;
        if (allModuleTypes) {
            setModuleTypes(allModuleTypes);
        }
        const orgResponse = OrganizationsData;
        if (orgResponse) {
            setOrganizations(orgResponse);
        }
        const roleResp = RolesData;
        if (roleResp) {
            setRoles(roleResp);
        }
        const userResp = TeamMembersData;
        if (userResp) {
            setUsers(userResp);
        }
    }, []);

    return (
        <Box sx={{ minHeight: '50vh' }}>
            <IconButton
                disabled={currentProject?.isArchived}
                sx={{
                    background: '#4cd137',
                    padding: 2,
                    '&:hover': {
                        background: '#218c74'
                    }
                }}
                onClick={() => setOpen(true)}
            >
                <AddCircleIcon sx={{ fontSize: 30, color: '#FFF' }} />
            </IconButton>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ borderBottom: '1px solid #000', marginBottom: 3 }}
                    >
                        <Grid item xs={6}>
                            <Typography variant="h4">
                                <AddCircleIcon /> {langString('createmodule')}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <IconButton
                                color="secondary"
                                onClick={() => setOpen(false)}
                                aria-label="closeModal"
                                component="span"
                                sx={{ color: 'black', float: 'right' }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>

                    <Grid container alignItems="center" spacing={gridSpacing} justifyContent="space-between" sx={{ padding: 2 }}>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="h6">{langString('moduletype')}</Typography>
                            <FormControl
                                fullWidth
                                error={Boolean(errors && errors.moduleType?.required)}
                                sx={{ ...theme.typography.customInput }}
                            >
                                <Select
                                    label={`${langString('moduletype')} *`}
                                    labelId="moduletype-select-label"
                                    id="moduletype-select"
                                    value={moduleType}
                                    onChange={(event: any) => setModuleType(event.target.value)}
                                    required
                                >
                                    <MenuItem value={0}>{langString('selectone')}</MenuItem>
                                    {moduleTypes &&
                                        moduleTypes.map((item: any, index: number) => (
                                            <MenuItem key={index} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                                {Boolean(errors && errors.moduleType?.required) && (
                                    <FormHelperText error>{errors.moduleType?.message}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <Typography variant="h5">{langString('access')}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" spacing={gridSpacing} justifyContent="space-between" sx={{ padding: 2 }}>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="h6">{`${langString('organization')} *`}</Typography>
                            <FormControl
                                fullWidth
                                error={Boolean(errors && errors.organization?.required)}
                                sx={{ ...theme.typography.customInput }}
                            >
                                <Select
                                    label={`${langString('organization')} *`}
                                    labelId="organization-select-label"
                                    id="organization-select"
                                    value={organization}
                                    onChange={(event: any) => setOrganization(event.target.value)}
                                    required
                                >
                                    <MenuItem value={0}>{langString('selectone')}</MenuItem>
                                    {organizations &&
                                        organizations.map((item: any, index: number) => (
                                            <MenuItem key={index} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                                {Boolean(errors && errors.organization?.required) && (
                                    <FormHelperText error>{errors.organization?.message}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="h6">{langString('role')}</Typography>
                            <FormControl
                                fullWidth
                                error={Boolean(errors && errors.role?.required)}
                                sx={{ ...theme.typography.customInput }}
                            >
                                <Select
                                    label={`${langString('role')} *`}
                                    labelId="role-select-label"
                                    id="role-select"
                                    value={role}
                                    onChange={(event: any) => setRole(event.target.value)}
                                    required
                                >
                                    <MenuItem value={0}>{langString('selectone')}</MenuItem>
                                    {roles &&
                                        roles.map((item: any, index: number) => (
                                            <MenuItem key={index} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                                {Boolean(errors && errors.role?.required) && <FormHelperText error>{errors.role?.message}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="h6">{langString('user')}</Typography>
                            <FormControl
                                fullWidth
                                error={Boolean(errors && errors.user?.required)}
                                sx={{ ...theme.typography.customInput }}
                            >
                                <Select
                                    label={`${langString('user')} *`}
                                    labelId="user-select-label"
                                    id="user-select"
                                    value={user}
                                    onChange={(event: any) => setUser(event.target.value)}
                                    required
                                >
                                    <MenuItem value={0}>{langString('selectone')}</MenuItem>
                                    {users &&
                                        users.map((item: any, index: number) => (
                                            <MenuItem key={index} value={item.id}>
                                                {item.username}
                                            </MenuItem>
                                        ))}
                                </Select>
                                {Boolean(errors && errors.user?.required) && <FormHelperText error>{errors.user?.message}</FormHelperText>}
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item xs={12} textAlign="center">
                            <Button
                                sx={{
                                    borderColor: theme.palette.error.dark,
                                    marginRight: 2
                                }}
                                onClick={() => setOpen(false)}
                                variant="contained"
                                color="inherit"
                            >
                                {langString('cancel')}
                            </Button>
                            <Button
                                disabled={isSubmitting}
                                color="primary"
                                variant="contained"
                                size="large"
                                onClick={() => createModule()}
                                autoFocus
                            >
                                {langString('create')}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </Box>
    );
}
