import { useEffect, useState } from 'react';

// material-ui
import CloseIcon from '@mui/icons-material/Close';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Box, Button, Checkbox, FormControlLabel, Grid, IconButton, Modal, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useSnackbar from 'hooks/useSnackbar';
import { useSelector } from 'react-redux';
import axiosServices from 'utils/axiosServices';
import langString from 'utils/langString';
import { settingsUpdateURL } from 'utils/serverUrls';
import { ProjectType } from '../Details/Constants';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 3
};

type ProjectSettingsPropsType = {
    project: ProjectType;
    title: string;
    published: boolean;
    active: boolean;
    archived: boolean;
    refresh: () => void;
};
export default function ProjectSettings({ project, title, published, active, archived, refresh }: ProjectSettingsPropsType) {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [proPublished, setProPublished] = useState(published);
    const [proActive, setProActive] = useState(active);
    const [proArchived, setProArchived] = useState(archived);
    const currentProject = useSelector((state: any) => state.projects.currentProject);
    const snackBar = useSnackbar();

    const saveSettings = async () => {
        try {
            const response = await axiosServices.post(settingsUpdateURL, {
                id: project.id,
                isPublished: proPublished,
                isActive: proActive,
                isArchived: proArchived
            });
            if (response.status === 200) {
                setOpen(false);
                snackBar('Project settings success', 'success');
                refresh();
            }
        } catch (error) {
            console.log(error);
            snackBar('Error updating project settings', 'error');
        }

        setOpen(false);
    };

    useEffect(() => {
        setProPublished(published);
        setProActive(active);
        setProArchived(archived);
    }, [published, active, archived]);

    return (
        <>
            <IconButton onClick={() => setOpen(true)} color="secondary" aria-label="settings" component="span" sx={{ color: 'black' }}>
                <SettingsOutlinedIcon />
            </IconButton>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ borderBottom: '1px solid #000', marginBottom: 3 }}
                    >
                        <Grid item xs={6}>
                            <Typography variant="h4">
                                {title} {langString('settings')}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <IconButton
                                color="secondary"
                                onClick={() => setOpen(false)}
                                aria-label="closeModal"
                                component="span"
                                sx={{ color: 'black', float: 'right' }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>

                    <Grid container alignItems="center" justifyContent="space-between" sx={{ marginBottom: 3, padding: 2 }}>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={proArchived}
                                        checked={proPublished}
                                        onChange={(event) => setProPublished(event.target.checked)}
                                        name="checked"
                                        color="primary"
                                    />
                                }
                                label={langString('publish')}
                            />
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={proArchived}
                                        checked={proActive}
                                        onChange={(event) => setProActive(event.target.checked)}
                                        name="checked"
                                        color="primary"
                                    />
                                }
                                label={langString('active')}
                            />
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={proArchived}
                                        onChange={(event) => {
                                            setProArchived(event.target.checked);
                                            if (event.target.checked) {
                                                setProActive(false);
                                                setProPublished(false);
                                            }
                                        }}
                                        name="checked"
                                        color="primary"
                                    />
                                }
                                label={langString('archived')}
                            />
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <Button
                                sx={{
                                    color: theme.palette.error.dark,
                                    borderColor: theme.palette.error.dark
                                }}
                                onClick={() => setOpen(false)}
                                color="secondary"
                            >
                                {langString('cancel')}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button color="primary" variant="contained" size="small" onClick={() => saveSettings()} autoFocus>
                                {langString('save')}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </>
    );
}
